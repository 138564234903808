import "alpinejs";
import { Module } from "@/helper/loadModules";

const modules: Module[] = [
  {
    id: "mobile-header",
    loadModule: () =>
      import(
        /* webpackChunkName: "mobile-header.module" */ "@/modules/mobile-header.module"
      ),
  },
  {
    id: "search",
    loadModule: () =>
      import(/* webpackChunkName: "search.module" */ "@/modules/search.module"),
  },
  {
    id: "prototype",
    loadModule: () =>
      import(
        /* webpackChunkName: "prototype.module" */ "@/modules/prototype.module"
      ),
  },
  {
    id: "tariff-calculator",
    loadModule: () =>
      import(
        /* webpackChunkName: "tariff-calculator.module" */ "@/modules/tariff-calculator.module"
      ),
  },
  {
    id: "usage-calculator",
    loadModule: () =>
      import(
        /* webpackChunkName: "usage-calculator.module" */ "@/modules/usage-calculator.module"
      ),
  },
  {
    id: "registration-route",
    loadModule: () =>
      import(
        /* webpackChunkName: "registration-route.module" */ "@/modules/registration-route.module"
      ),
  },
  {
    id: "sales-application-route",
    loadModule: () =>
      import(
        /* webpackChunkName: "sales-application-route.module" */ "@/modules/sales-application-route.module"
      ),
  },
  {
    id: "news-slider",
    loadModule: () =>
      import(
        /* webpackChunkName: "news-slider.module" */ "@/modules/news-slider.module"
      ),
  },
  {
    id: "tile-slider",
    loadModule: () =>
      import(
        /* webpackChunkName: "tile-slider.module" */ "@/modules/tile-slider.module"
      ),
  },
  {
    id: "text-image-slider",
    loadModule: () =>
      import(
        /* webpackChunkName: "text-image-slider.module" */ "@/modules/text-image-slider.module"
      ),
  },
  {
    id: "home-video-element",
    loadModule: () =>
      import(
        /* webpackChunkName: "home-video-element.module" */ "@/modules/home-video-element.module"
      ),
  },
  {
    id: "charging-stations-map",
    loadModule: () =>
      import(
        /* webpackChunkName: "charging-stations-map.module" */ "@/modules/charging-stations-map.module"
      ),
  },
  {
    id: "rasmus-user-registration",
    loadModule: () =>
      import(
        /* webpackChunkName: "rasmus-user-registration.module" */ "@/modules/rasmus-kids-club/rasmus-user-registration.module"
      ),
  },
  {
    id: "rasmus-reset-password-from-hash",
    loadModule: () =>
      import(
        /* webpackChunkName: "rasmus-reset-password-from-hash.module" */ "@/modules/rasmus-kids-club/rasmus-reset-password-from-hash.module"
      ),
  },
  {
    id: "rasmus-user-login",
    loadModule: () =>
      import(
        /* webpackChunkName: "rasmus-user-login.module" */ "@/modules/rasmus-kids-club/rasmus-user-login.module"
      ),
  },
  {
    id: "rasmus-user-logout",
    loadModule: () =>
      import(
        /* webpackChunkName: "rasmus-user-logout.module" */ "@/modules/rasmus-kids-club/rasmus-user-logout.module"
      ),
  },
  {
    id: "rasmus-forgot-password",
    loadModule: () =>
      import(
        /* webpackChunkName: "rasmus-forgot-password.module" */ "@/modules/rasmus-kids-club/rasmus-forgot-password.module"
      ),
  },
  {
    id: "rasmus-show-user",
    loadModule: () =>
      import(
        /* webpackChunkName: "rasmus-show-user.module" */ "@/modules/rasmus-kids-club/rasmus-show-user.module"
      ),
  },
  {
    id: "rasmus-edit-user",
    loadModule: () =>
      import(
        /* webpackChunkName: "rasmus-edit-user.module" */ "@/modules/rasmus-kids-club/rasmus-edit-user.module"
      ),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(
        /* webpackChunkName: "lightbox.module" */ "@/modules/lightbox.module"
      ),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(/* webpackChunkName: "swiper.module" */ "@/modules/swiper.module"),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(
        /* webpackChunkName: "notification.module" */ "@/modules/notification.module"
      ),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(
        /* webpackChunkName: "site-content.module" */ "@/modules/site-content.module"
      ),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(/* webpackChunkName: "scroll.module" */ "@/modules/scroll.module"),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(/* webpackChunkName: "forms.module" */ "@/modules/forms.module"),
  },
  {
    id: "newsletter-registration",
    loadModule: () =>
      import(
        /* webpackChunkName: "newsletter-registration.module" */ "@/modules/newsletter-registration.module"
      ),
  },
];

export default modules;
