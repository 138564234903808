export interface Module {
  loadModule: () => Promise<unknown>;
  id?: string;
  loadGlobal?: boolean;
}

class LoadModulesError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "LoadModulesError";
  }
}

function throwLoadModulesError(message: string): never {
  throw new LoadModulesError(message);
}

function loadModules(modules: Module[]): void {
  modules.forEach((module) => {
    const { loadModule, id = "", loadGlobal = false } = module;

    if (!loadModule) {
      throwLoadModulesError(
        "Please define a loadModule function for passed module."
      );
    }

    if (!loadGlobal && !id) {
      throwLoadModulesError("Please define an id for passed module.");
    }

    if (
      loadGlobal ||
      document.getElementById(id) ||
      Array.from(document.querySelectorAll(`.${id}`)).length > 0
    ) {
      loadModule();
    }
  });
}

export default loadModules;
