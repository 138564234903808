import "./config";

import "@/helper/initValidation";

import loadModules from "@/helper/loadModules";
import modules from "@/modules";

import "@/assets/css/main.pcss";

loadModules(modules);
