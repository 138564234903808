import { extend, localize } from "vee-validate";
import de from "vee-validate/dist/locale/de.json";
import {
  confirmed,
  digits,
  email,
  length,
  numeric,
  required,
  required_if,
  alpha_dash,
  alpha_num,
  max,
  min,
} from "vee-validate/dist/rules";
import PhoneNumber from "awesome-phonenumber";
import IBAN from "iban";

const rules = {
  required,
  required_if,
  digits,
  numeric,
  length,
  email,
  confirmed,
  alpha_num,
  alpha_dash,
  max,
  min,
};

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, validation);
}

extend("phone", {
  message(fieldName) {
    return `${fieldName} ist keine valide Telefonnummer`;
  },
  validate(value) {
    return new Promise((resolve) => {
      const phone = new PhoneNumber(String(value), "DE");
      const valid = phone.isValid();

      resolve({ valid });
    });
  },
});
extend("iban", (value) => {
  if (IBAN.isValid(value)) {
    return true;
  }

  return "Bitte eine korrekte IBAN eingeben";
});

localize("de", {
  messages: {
    ...de.messages,
    email: "Bitte geben Sie eine korrekte Email-Adresse ein",
  },
  fields: {
    email: {
      confirmed: "Die E-Mail-Adresse stimmt nicht überein",
      required: "Email-Adresse ist ein Pflichtfeld",
    },
  },
});
